import { ListItem, ListItemText } from "@clipboard-health/ui-components";
import { Box, Stack } from "@mui/material";
import { type GeoLocation } from "@src/appV2/Location/types";

import { MapWithAddress } from "../GeoLocation/MapWithAddress";
import { WorkplaceHighlightPills } from "../Workplace/Highlights/Pills";
import { useWorkplacePolicyHighlights } from "../Workplace/Highlights/useWorkplacePolicyHighlights";
import { useWorkplaceReviewHighlights } from "../Workplace/Highlights/useWorkplaceReviewHighlights";

interface ShifttHighlightsAndMapListItemProps {
  workplaceId: string;
  workplaceName: string;
  workplaceLocation: GeoLocation;
  lateCancellationPeriod: number;
  lateCancellationFeeHours: number;
  requiresLunchBreak?: boolean;
  providesRestBreaks?: boolean;
}

/**
 * When there are fewer than 5 highlights, display each one on its own row to improve visual balance.
 * This prevents the card from looking too empty with sparse content.
 */
const HIGHLIGHTS_WRAP_THRESHOLD = 5;

export function ShifttHighlightsAndMapListItem(props: ShifttHighlightsAndMapListItemProps) {
  const {
    workplaceId,
    lateCancellationPeriod,
    lateCancellationFeeHours,
    requiresLunchBreak,
    providesRestBreaks,
    workplaceName,
    workplaceLocation,
  } = props;

  const { highlights: policyHighlights, isLoading: isPolicyHighlightsLoading } =
    useWorkplacePolicyHighlights({
      workplaceId,
      lateCancellationPeriod: lateCancellationPeriod ?? 0,
      lateCancellationFeeHours: lateCancellationFeeHours ?? 0,
      requiresLunchBreak,
      providesRestBreaks,
    });

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  const highlights = [...policyHighlights, ...reviewHighlights];

  return (
    <ListItem divider sx={{ paddingTop: 0 }}>
      <ListItemText
        disableTypography
        sx={{ paddingLeft: "2rem" }}
        primary={
          <Stack spacing={4}>
            <WorkplaceHighlightPills
              highlights={highlights}
              isLoading={isReviewHighlightsLoading || isPolicyHighlightsLoading}
              wrapperSx={(theme) => ({
                marginTop: `calc(-1 * ${theme.spacing(3)})`,
                maxWidth: highlights.length > HIGHLIGHTS_WRAP_THRESHOLD ? "100%" : "min-content",
              })}
            />

            <Box sx={{ height: "13rem", width: "100%" }}>
              <MapWithAddress
                readonly
                workplaceId={workplaceId}
                workplaceName={workplaceName}
                location={{
                  lat: workplaceLocation.latitude,
                  lng: workplaceLocation.longitude,
                }}
              />
            </Box>
          </Stack>
        }
      />
    </ListItem>
  );
}
